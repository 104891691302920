import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: "1rem"
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    width: 560
  },
  button: {
    border: 0,
    padding: '.5rem 1rem',
    borderRadius: 80,
  }
}));

export const Dashboard = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/c-F80pnDWcc?si=m4Z2askMOiPJv-1j" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

      <div className={classes.buttonWrapper}>
        <button className={classes.button} onClick={() => window.open('https://tikspy.com.br/suporte', "_blank")}>Suporte</button>
        <button className={classes.button} onClick={() => window.open('https://tikspy.com.br/instalar', "_blank")}>Instalar ferramenta</button>
      </div>
    </div>
  )
}