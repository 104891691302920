import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Signup from "../pages/signup";
import Login from "../pages/login";
import forgotpd from "../pages/forgotpd"
import { AuthProvider } from "../context/Auth/AuthContext";
import Route from "./Route";
import LoggedInLayout from "../layout";
import Recoverypage from "../pages/recovery"
//import Dashboard from "../pages/dashboard"
import verifycompletion from "../pages/verifycompletion"
import Users from "../pages/users";
import Planos from "../pages/Planos";
import add from "../pages/add"
import cafelindo from "../pages/cafelindo"
import { Dashboard } from "../pages/Dashboard";
const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/forgotpd" component={forgotpd} />
          <Route exact path="/recovery/:recoveryCode" component={Recoverypage} />
          <Route exact path="/emailvalidation/:recoveryCode" component={verifycompletion}/>
          <Route exact path="/add/:recoveryCode" component={add}/>
            <LoggedInLayout>
            
            <Route exact path="/users" component={Users} isPrivate/>
            <Route exact path="/planos" component={Planos} isPrivate/>
            <Route exact path="/cafelindo" component={cafelindo} isPrivate/>
            <Route exact path="/" component={Dashboard} isPrivate />
            
           {/*     <Route
                exact
                path="/tickets/:ticketId?"
                component={Tickets}
                isPrivate
              />
             
              <Route
                exact
                path="/connections"
                component={Connections}
                isPrivate
              />
              <Route exact path="/contacts" component={Contacts} isPrivate />
              <Route exact path="/users" component={Users} isPrivate />
              <Route
                exact
                path="/quickAnswers"
                component={QuickAnswers}
                isPrivate
              />
              <Route exact path="/Settings" component={Settings} isPrivate />
              <Route exact path="/Queues" component={Queues} isPrivate />
      */}
            </LoggedInLayout>
      
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;